<template>
  <div class="container-top">
    <div class="container" style="padding-top:150px">
      <div style="text-align:center">
        <img src="/static/icon/u_check-circle.svg" class="svg-primary"
             style="width:54px;height:54px;margin-bottom: 32px">
        <div class="h4 main">결제가 완료되었습니다.</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ServiceAccountSimpleDone",
    created() {

    },
    data() {
      return {}
    },
    methods: {
    },
    computed: {

    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .box-info
    background-color $gray4
    padding 40px
    border-radius 8px
    display flex
    flex-wrap wrap
    text-align left
    width 564px
    margin 32px auto

  .box-title
    padding 16px 0
    border-bottom 1px solid $sub5
    font-weight 700
    font-size 18px
    line-height 24px
    margin-top 70px

  .item-col
    padding-top 24px
</style>
